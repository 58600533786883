.App {
  text-align: left;
  margin: 2em 2em;
  display: flex;
  align-items: flex-start;
  align-items: stretch;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.app-table {
  width: 100%;
}

.row-1 {
  height: 100%;
}

.header-name, .side-panel-header {
  font-size: 1.5em;
  font-weight: bold;
}

.user-column {
  height: 100%;
  width: 10em;
}

.message-column {
  width: calc(100% - 10em);
}

.side-panel {
  height: 100%;
  width: 100%;
  display: inline-block;
}

.user-list {
  padding: 0 0.5em;
}

.user-name {
  padding: 0.5em 0;
}

.main-panel {
  display: inline-block;
  width: 100%;
}

.message-panel {
  margin: 0em 0;
}

.channel {
  margin-right: 1em;
  color: rgba(0, 0, 128, 0.5);
  font-style: italic
}

.date-separator {
  margin: 1.0em 0;
  padding: 0.2em 0;
  border: 1px solid #a0a0a0;
  text-align: center;
  background-color: #FFEC8B;
}

.main-panel-body {
  min-height: 50vh;
  height: calc(100vh - 400px);
  overflow-y: scroll;
}

.user {
  font-weight: bold;
  margin-right: 1em;
}

.time {
  color: #555;
  font-size: 0.8em;
}

.message-content {
  margin: 0.5em 0;
}

.form-group {
  width: 100%;
}
